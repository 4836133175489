export const getPeriod = (bffPeriod) =>{
    bffPeriod = bffPeriod.toUpperCase()
    let str
    switch (bffPeriod) {
        case 'WEEKLY':
            str = 'semanal'
            break
        case 'BIWEEKLY':
            str = 'quincenal'
            break
        case 'MONTHLY':
            str = 'mensual'
            break
        case 'SEMIMONTHLY':
            str = 'catorcenal'
            break
    }
    return str
}

export const getPeriodPlural = (bffPeriod) =>{
    bffPeriod = bffPeriod.toUpperCase()
    let str
    switch (bffPeriod) {
        case 'WEEKLY':
            str = 'semanas'
            break
        case 'BIWEEKLY':
            str = 'quincenas'
            break
        case 'MONTHLY':
            str = 'meses'
            break
        case 'SEMIMONTHLY':
            str = 'catorcenas'
            break
    }
    return str
}